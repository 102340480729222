<template>
  <div class="bbr-settings--food-category">
    <main-app-bar>
      <template v-slot:title> Food Category Settings </template>
    </main-app-bar>

    <div class="mt-7 px-10 pb-6">
      <v-row>
        <v-col cols="5">
          <form autocomplete="off">
            <food-category-form :form-data="form" />
            <v-row>
              <v-col>
                <v-btn
                  class="bg-primary-gradient primary ml-auto mt-4"
                  depressed
                  @click="store"
                >
                  <v-icon class="mr-2">{{ icons.save }}</v-icon>
                  <span v-if="isNew">Save Settings</span>
                  <span v-else>Update Settings</span>
                </v-btn>
              </v-col>
              <v-col>
                <div class="text-right">
                  <v-btn
                    v-if="!isNew"
                    text
                    depressed
                    @click="clearForm"
                    class="mt-3"
                  >
                    Cancel
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </form>
        </v-col>
        <v-col cols="7">
          <list-table :loading="loading" @edit="edit" :list="foodCategories" />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { mdiContentSaveOutline, mdiCancel } from '@mdi/js'
import MainAppBar from '@/layouts/shared/MainAppBar'
import ListTable from './components/ListTable'
import FoodCategoryForm from './components/FoodCategoryForm'
import Form from '@/utils/form'

const emptyFields = {
  name: null,
  is_booster: false,
  is_active: false,
  order: 0,
  span: 1,
  image_public_id: '',
}

export default {
  name: 'FoodCategorySettingsPage',

  components: {
    MainAppBar,
    ListTable,
    FoodCategoryForm,
  },

  data() {
    return {
      icons: { save: mdiContentSaveOutline, cancel: mdiCancel },
      loading: false,

      isNew: true,
      form: new Form(emptyFields),
    }
  },

  computed: {
    ...mapState({
      foodCategories: (state) => state.foodCategory.list,
    }),
  },

  created() {
    this.getFoodCategory({ search: null })
  },

  destroyed() {
    this.clearList()
  },

  methods: {
    ...mapActions({
      getFoodCategory: 'foodCategory/getDataList',
      saveData: 'foodCategory/saveData',
    }),

    ...mapMutations({
      clearList: 'foodCategory/clearList',
    }),

    edit(row) {
      let payload = {
        id: row.id,
        name: row.name,
        is_booster: row.is_booster,
        is_active: row.is_active,
        order: row.order,
        span: row.span,
        image_public_id: row.cloudinary_image
          ? row.cloudinary_image.public_id
          : null,
      }

      this.form = new Form(payload)
      this.isNew = false
    },

    clearForm() {
      this.form = new Form(emptyFields)
      this.isNew = true
    },

    store() {
      this.form.$busy = true
      this.form.$clearErrors()

      const payload = this.form.$data()

      this.saveData(payload)
        .then((data) => {
          this.form.$timeout(() => {
            this.form.$busy = false
            this.clearForm()
            this.getFoodCategory({ search: null })
          })
        })
        .catch((err) => {
          this.form.$timeout(() => {
            if (err.response && err.response.status === 422) {
              this.form.$setErrors(err.response.data.errors)
            }

            this.form.$busy = false
          })
        })
    },
  },
}
</script>
