<template>
  <div class="bbr-workout-extra-categories">
    <main-app-bar :showSearchIcon="false">
      <template v-slot:title> Training Program Settings </template>
    </main-app-bar>

    <div class="px-12 mb-12">
      <v-row>
        <v-col cols="12" sm="4">
          <div class="mt-10 mb-5">
            <h3>Venue</h3>
          </div>
          <list
            :items="venueWorkoutTypeTrainingPrograms()"
            :loading="isFetchingTrainingPrograms"
            alias="Venue Program"
            :menu="workoutExtraCategoryMenu"
            @menu-item-click="menuItemClick"
            @new-list-item="newTrainingProgram(1)"
            :is-sortable-list="false"
          />
        </v-col>
        <v-col cols="12" sm="4">
          <div class="mt-10 mb-5">
            <h3>Training</h3>
          </div>
          <list
            :items="trainingWorkoutTypeTrainingPrograms()"
            :loading="isFetchingTrainingPrograms"
            alias="Training Program"
            :menu="workoutExtraCategoryMenu"
            @menu-item-click="menuItemClick"
            @new-list-item="newTrainingProgram(2)"
            :is-sortable-list="false"
          />
        </v-col>
      </v-row>
    </div>

    <add-training-program-modal
      v-model="targetTrainingProgram"
      @save="saveWorkoutExtraCategory"
      @close="targetTrainingProgram = null"
    />
    <confirm-dialog ref="confirmDelete" />
  </div>
</template>

<script>
import AddTrainingProgramModal from '@/components/modals/settings/AddTrainingProgramModal'
import ConfirmDialog from '@/components/modals/ConfirmDialog'
import List from '@/components/elements/workout-extras/List'
import MainAppBar from '@/layouts/shared/MainAppBar'

import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { mdiMagnify, mdiPlus } from '@mdi/js'

const MENU_EDIT = { title: 'Edit' }
const MENU_DELETE = {
  title: 'Delete',
  class: 'red--text px-1',
}

export default {
  name: 'TrainingProgramsPage',

  components: {
    AddTrainingProgramModal,
    ConfirmDialog,
    MainAppBar,
    List,
  },

  data() {
    return {
      isFetchingTrainingPrograms: false,
      workoutExtraCategoryMenu: [MENU_EDIT, MENU_DELETE],
      targetTrainingProgram: null,
      icons: {
        add: mdiPlus,
        search: mdiMagnify,
      },
    }
  },

  created() {
    this.fetchTrainingPrograms()
  },

  computed: {
    ...mapState({
      selectedWorkoutExtraCategory: (state) =>
        state.workoutExtraCategories.selectedWorkoutExtraCategory,
      selectedWorkoutExtraSubCategory: (state) =>
        state.workoutExtraCategories.selectedWorkoutExtraSubCategory,
    }),

    ...mapGetters({
      venueWorkoutTypeTrainingPrograms:
        'trainingPrograms/getVenueWorkoutTypeTrainingPrograms',
      trainingWorkoutTypeTrainingPrograms:
        'trainingPrograms/getTrainingWorkoutTypeTrainingPrograms',
    }),
  },

  methods: {
    ...mapActions({
      getTrainingPrograms: 'trainingPrograms/getTrainingPrograms',
      saveTrainingPrograms_: 'trainingPrograms/saveTrainingProgram',
      deleteTrainingPrograms: 'trainingPrograms/deleteTrainingProgram',
    }),

    ...mapMutations({
      setVenueTrainingPrograms: 'trainingPrograms/setVenueTrainingPrograms',
      setTrainingTrainingPrograms:
        'trainingPrograms/setTrainingTrainingPrograms',
      setSelectedTrainingProgram: 'trainingPrograms/setSelectedTrainingProgram',
      updateOrInsertTrainingProgramItem:
        'trainingPrograms/updateOrInsertTrainingProgramItem',
      removeTrainingProgramItem: 'trainingPrograms/removeTrainingProgramItem',
    }),

    async fetchTrainingPrograms(page = 1) {
      /* eslint-disable */
      if (this.isFetchingTrainingPrograms) return

      this.isFetchingTrainingPrograms = true

      let { data: venueData, meta: venueMeta } = await this.getTrainingPrograms({ page ,workoutTypeId: 1 })
      let { data: trainingData, meta: trainingMeta } = await this.getTrainingPrograms({ page ,workoutTypeId: 2 })

      this.isFetchingTrainingPrograms = false
      if (venueData.length) {
        venueData.forEach(item => {
          item.selected = false
        })
      }
      this.setVenueTrainingPrograms({ venueData, venueMeta })
      this.setTrainingTrainingPrograms({ trainingData, trainingMeta })
    },
    async showConfirmationDialog(action, category) {
      return this.$refs.confirmDelete.open(
        `${action} ${category}`,
        `Are you sure you want to ${action.toLowerCase()} this?`
      )
    },
    async menuItemClick({ item, menuItem }) {
      /* eslint-disable */
      this.setSelectedTrainingProgram(item)

      if (menuItem.title === 'Edit') {
        this.targetTrainingProgram = item
      } else if (menuItem.title === 'Delete') {
        const confirm = await this.showConfirmationDialog(
          menuItem.title,
          'Training Program'
        )
        if (
          confirm &&
          (
            await this.deleteTrainingPrograms(item)
          ).status === 200
        ) {
          this.removeTrainingProgramItem(item)
        }
      }
    },
    newTrainingProgram(workoutTypeId) {
      this.targetTrainingProgram = {workout_type_id:workoutTypeId}
    },
    saveWorkoutExtraCategory(form) {
      form.$busy = true
      form.$clearErrors()

      this.saveTrainingPrograms_(form)
        .then(data => {
          this.updateOrInsertTrainingProgramItem(data)
          this.targetTrainingProgram = null
          form.$busy = false
          form.$reset()
        })
        .catch(({ response }) => {
          form.$timeout(() => {
            form.$busy = false

            if (response && response.status === 422) {
              form.$setErrors(response.data.errors)
            }
          })
        })
    },
  }
}
</script>

<style lang="scss">
.v-card {
  display: flex !important;
  flex-direction: column;

  .category-list {
    &--container {
      flex-grow: 1;
      overflow: auto;
    }

    &--item {
      &:hover {
        cursor: pointer;
        // background-color: var(--v-primary-lighten4) !important;
      }
    }
  }
}
</style>
