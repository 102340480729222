<template>
  <v-row class="bbr-program--details-form">
    <v-col cols="12">
      <v-text-field
        hide-details
        v-model="form.name"
        label="Name"
        :error-messages="form.$getError('name')"
        outlined
        flat
      />
      <v-checkbox
        v-model="form.is_booster"
        label="Booster"
        hide-details
      ></v-checkbox>
      <v-checkbox
        v-model="form.is_active"
        label="Active"
        hide-details
      ></v-checkbox>
      <v-row>
        <v-col cols="4">
          <v-text-field
            class="pt-5"
            width="8px"
            hide-details
            v-model="form.order"
            label="Order"
            :error-messages="form.$getError('order')"
            outlined
            flat
          />
        </v-col>
        <v-col>
          <v-select
            class="pt-5"
            v-model="form.span"
            :items="spanValues"
            item-text="name"
            item-value="id"
            label="Span"
            outlined
            flat
            :error-messages="form.$getError('span')"
          ></v-select>
        </v-col>
      </v-row>
      <v-text-field
        class="pt-5"
        hide-details
        v-model="form.image_public_id"
        label="Featured Image"
        :error-messages="form.$getError('image_public_id')"
        outlined
        flat
      />
    </v-col>
  </v-row>
</template>

<script>
import Form from '@/utils/form'

export default {
  name: 'FoodCategoryForm',

  props: {
    formData: {
      type: Form,
      required: true,
    },
  },

  components: {},

  data() {
    return {
      form: this.formData,
      spanValues: [
        { name: 'Occupy half of the space', id: 1 },
        { name: 'Occupy all space', id: 2 },
      ],
    }
  },

  watch: {
    formData() {
      this.form = this.formData
    },
  },

  computed: {},

  methods: {},
}
</script>
